export default class AbsenceModeEntity {
    static getEntityData(elem: any) {
        return {
            uuid: elem.querySelector("#absence_mode_uuid") ? (elem.querySelector("#absence_mode_uuid") as HTMLInputElement).value : null,
            name: (elem.querySelector("#absence_mode_name") as HTMLInputElement).value,
            hours: (elem.querySelector("#absence_mode_hours") as HTMLInputElement).value,
            book_mode: (elem.querySelector("#absence_mode_book_mode option:checked") as HTMLInputElement).value,
            deduct_from_vacation_days: (elem.querySelector("#absence_mode_deduct_from_vacation_days") as HTMLInputElement).checked,
            admin_only: (elem.querySelector("#absence_mode_admin_only") as HTMLInputElement).checked,
            is_sick: (elem.querySelector("#absence_mode_is_sick") as HTMLInputElement).checked,
        }
    }
}